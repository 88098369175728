const axios = require("axios").default

const defaultHeaders = {
    "Content-Type": "application/json"
}

const apiUrl = process.env.API_HOST
const apiVersion = process.env.API_VERSION

export class Http {
    post(endpoint, params, callback) {
        axios({
            method: "post",
            url: `${apiUrl}/${apiVersion}/${endpoint}`,
            data: params,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                callback(err)
            })
    }

    get(endpoint, params, callback) {
        axios({
            method: "get",
            url: `${apiUrl}/${apiVersion}/${endpoint}`,
            params,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                callback(err)
            })
    }

    getWithContext(endpoint, params, callback, cancelToken) {
        axios({
            method: "get",
            url: `${apiUrl}/${apiVersion}/${endpoint}`,
            params,
            cancelToken: cancelToken.token,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                }
                callback(err)
            })
    }

    getV2(endpoint, params, callback) {
        axios({
            method: "get",
            url: `${apiUrl}/v2/${endpoint}`,
            params,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                callback(err)
            })
    }

    getFromExternalAPI(endpoint, params, callback) {
        axios({
            method: "get",
            url: endpoint,
            params,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                callback(err)
            })
    }

    put(endpoint, params, callback) {
        axios({
            method: "put",
            url: `${apiUrl}/${apiVersion}/${endpoint}`,
            data: params,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                callback(err)
            })
    }

    delete(endpoint, params, callback) {
        axios({
            method: "delete",
            url: `${apiUrl}/${apiVersion}/${endpoint}`,
            data: params,
            headers: defaultHeaders,
        })
            .then(res => {
                callback(null, res)
            })
            .catch(err => {
                callback(err)
            })
    }
}
